export const theme = {
    global: {
      colors: {
        brand: '#228BE6',
      },
      font: {
        family: "Roboto",
        size: "18px",
        height: "20px",
      },
    },
  };
  